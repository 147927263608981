import React from 'react';
import HomepageV2 from './HomepageV2';

function App() {
    return (
        <div className='App'>
            <HomepageV2 />
        </div>
    );
}

export default App;
