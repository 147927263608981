import React from 'react';
import'./WorkSection.css';

const WorkSection = () => {
  const workItems = [
    {
      link: 'primeprotocol.xyz',
      description: 'cross-chain lending protocol - backend'
    },
    {
      link: 'hyperlane.xyz',
      description: 'open interoperability framework - backend'
    },
    {
      link: 'justwalkout.com',
      description: 'computer-vision based grocery store - ml integration & fullstack'
    },
    {
      link: 'developer.amazon.com/alexa',
      description: 'smart home virtual assistant - ml analysis & fullstack'
    },
    {
      link: 'samcap.ltd',
      description: 'hedgefund - frontend'
    }
  ];

  return (
    <section>
      <h2>public achievements</h2>
      <ul className="work-list">
        {workItems.map(({ link, description }) => (
          <li key={link} className="work-item">
            <a href={`https://www.${link}`} target="_blank" rel="noopener noreferrer" className="work-link">
              {link}
            </a>
            <p className="work-description">{description}</p>
          </li>
        ))}
      </ul>
      <h2>private achievements</h2>
      <p>nice try</p>
    </section>
  );
};

export default WorkSection;
